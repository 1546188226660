import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 3, 2),
    minHeight: "100%",
    width: "100%",
  },
  table: {
    minWidth: 700,
  },
  textAlert: {
    color: theme.palette.warning,
  },
  iconRed: {
    color: "red",
  },
}));
export default useStyles;
