import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  pageName: {
    paddingTop: theme.spacing(3),
    font: "var(--unnamed-font-style-normal) normal bold 30px/34px var(--unnamed-font-family-arial)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    color: "var(--unnamed-color-ffffff)",
    opacity: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  iconRed: {
    color: "red",
  },
}));
export default useStyles;
