/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { selectUser } from 'src/redux/slices/authSlice';
import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { acceptLegalAgreement, checkLegalAgreement } from 'src/services/complexServices';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavBar from './NavBar';
import TopBar from './TopBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as FirestoreService from '../../views/viewAdministrador/mantenimiento/services/firestore';
import * as FirestoreService1 from '../../views/viewAdministrador/issuesReport/services/firestore';
import './scrollbar.css';

const Swal = require('sweetalert2');

const useStyles = makeStyles((theme) => ({
  rootAdmin: {
    background: '#fffff',
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  rootAsociado: {
    background:
      'transparent radial-gradient(closest-side at 50% 50%, #003C6B 0%, #001E36 100%) 0% 0% no-repeat padding-box',
    border: '1px solid #707070',
    opacity: 1,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  rootVendedor: {
    border: '1px solid var(--unnamed-color-707070)',
    background:
      'transparent linear-gradient(51deg, #707070 0%, #6F6F7A 14%, #6F6F7B 16%, #6B6CB0 100%) 0% 0% no-repeat padding-box',
    opacity: 1,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 40,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
    marginTop: theme.spacing(5),
  },
  wrapperAsociado: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    marginTop: theme.spacing(5),
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));
const DashboardLayout = ({ children }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const user = useSelector(selectUser);
  const conjunto = useSelector((state) => state.complex.complex);

  useEffect(() => {
    const fetchLegalAgreement = async () => {
      if (user.id) {
        const checkLegal = await checkLegalAgreement(user.id);
        if (!checkLegal) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons
            .fire({
              title: "Términos y Condiciones",
              html: `
              <p style="text-align: justify;">
                Para continuar, por favor revisa nuestros 
                <a href="https://www.tglabsecuador.com/mi-casita#comp-krgvr8r7" target="_blank" style="color: #007bff; text-decoration: none;">
                Términos y Condiciones.
                </a><br/>
              </p>
              <label style="display: flex; align-items: center; gap: 8px;">
                <input type="checkbox" id="acceptTerms">
                <span>He leído y acepto los términos y condiciones de uso.</span>
              </label>`,
              imageUrl:
                "https://cdn-icons-png.flaticon.com/512/10348/10348844.png",
              imageWidth: 150,
              imageHeight: 150,
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCloseButton: false,
              didOpen: () => {
                const confirmButton = Swal.getConfirmButton();
                const checkbox = document.getElementById("acceptTerms");
                confirmButton.disabled = true;
                checkbox.addEventListener("change", (e) => {
                  confirmButton.disabled = !e.target.checked;
                });
              },
            })
            .then(async (result) => {
              const isChecked = document.getElementById("acceptTerms").checked;
              if (result.isConfirmed) {
                if (isChecked) {
                  try {
                    const data = {
                      AceptaAcuerdoLegal: true,
                      fecha: moment(Date.now()).toLocaleString(),
                    };
                    await acceptLegalAgreement(user.id, data);
                    iniciarSuscripciones();
                  } catch (error) {
                    enqueueSnackbar(
                      "Error al actualizar los términos y condiciones.",
                      { variant: "error" }
                    );
                  }
                }
              }
            });
        } else {
          iniciarSuscripciones();
        }
      }
    };
      fetchLegalAgreement();
  }, [conjunto.id]);
  
  const iniciarSuscripciones = () => {
    if (conjunto.id) {
      const unsubPending = FirestoreService.suscriberPendingMaintenances(
        {
          next: (query) => {
            const Items = query.docs
            if (Items.length > 0) {
              enqueueSnackbar('Mantenimientos Pendientes', { variant: 'error' });
              const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-success',
                  cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false,
              });
              swalWithBootstrapButtons
                .fire({
                  title: 'Existen mantenimientos Pendientes',
                  text: Items[0]?.data().Detalle,
                  icon: 'warning',
                  imageUrl: Items[0]?.data().Imagen,
                  imageWidth: 400,
                  imageHeight: 200,
                  confirmButtonText: 'Detalle',
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    history.push('/administrador/mantenimiento');
                  }
                });
            }
          },
        },
        conjunto.id,
      );

      const unsubAlert = FirestoreService1.getAlertareporte(
        conjunto.id,
        {
          next: (query) => {
            const Items = query.docs

            if (Items.length > 0) {
              enqueueSnackbar('Reporte de Daños Pendientes', { variant: 'error' });
              const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-success',
                  cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false,
              });
              swalWithBootstrapButtons
                .fire({
                  title: 'Reporte de Daños Pendientes',
                  text: Items[0]?.data().Observaciones,
                  icon: 'warning',
                  imageUrl: Items[0]?.data().Imagen,
                  imageWidth: 400,
                  imageHeight: 200,
                  confirmButtonText: 'Detalle',
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    history.push('/administrador/informeProblemas');
                  }
                });
            }
          },
          error: (e) => {
            enqueueSnackbar('Error al cargar los reportes de daños', { variant: 'error' });
          }
        },

      );

      return () => {
        unsubAlert()
        unsubPending()
      }
    }
  }

  return (
    <div className={classes.rootAdmin}>
      <TopBar img={conjunto.Imagen} />
      <NavBar />

      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={`${classes.content} scroll`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

DashboardLayout.defaultProps = {
  children: '',
};

export default DashboardLayout;
