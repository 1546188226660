/* eslint-disable no-undef */
import {
  Card,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { toDecimal } from "src/utils/toDecimal";
import { BarChart } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { CustomModal, StyledCell, StyledRow } from "src/components/Common";
import { getRespuestasSurvey } from "src/services/admin/vote";
import useStyles from "../../votacion/Styles";
import DetalleResult from "./DetalleResult";

const ResultadoSurvey = ({ encuesta, conjunto, usuarios }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [resultados, setResultados] = useState([]);
  const [opcionesPorcentaje, setOpcionesPorcentaje] = useState([]);

  useEffect(() => {
    const observer = (snapshot) => {
      try {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResultados(data);
        const totalRespuestas = data.length;
        const conteoSi = data.filter((respuesta) => respuesta.confirm).length;
        const conteoNo = totalRespuestas - conteoSi;
        const porcentajeSi = toDecimal((conteoSi / totalRespuestas) * 100);
        const porcentajeNo = toDecimal((conteoNo / totalRespuestas) * 100);
        setOpcionesPorcentaje([
          {
            nombre: "Sí",
            porcentaje: porcentajeSi,
            cantidad: conteoSi,
            opcion: true,
          },
          {
            nombre: "No",
            porcentaje: porcentajeNo,
            cantidad: conteoNo,
            opcion: false,
          },
        ]);
      } catch (error) {
        enqueueSnackbar("Error al cargar los resultados", {
          variant: "error",
        });
      }
    };
    const unsubscribe = getRespuestasSurvey(conjunto.id, encuesta.id, observer);
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [encuesta]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Ver resultados">
        <IconButton onClick={handleOpen} color="primary">
          <BarChart />
        </IconButton>
      </Tooltip>
      <CustomModal
        isOpen={open}
        closeModal={handleClose}
        title={<b>{encuesta.title}</b>}
      >
        <Grid
          xs={12}
          sm={12}
          container
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <Grid item xs={12} sm={8} className={classes.chart}>
            <Card>
              <CardHeader
                title={`Total de encuestados: ${resultados.length.toString()}`}
              />
              <div style={{ maxWidth: "450px", margin: "0 auto" }}>
                <Doughnut
                  data={{
                    labels: opcionesPorcentaje.map((opcion) => opcion.nombre),
                    datasets: [
                      {
                        data: opcionesPorcentaje.map(
                          (opcion) => opcion.cantidad
                        ),
                        backgroundColor: [
                          "rgba(54, 162, 235, 0.6)",
                          "rgba(255, 99, 132, 0.6)",
                        ],
                      },
                    ],
                  }}
                />
              </div>
              <div>
                <h4>Opciones y porcentajes:</h4>
                <TableContainer component={Paper} style={{ maxHeight: 440 }}>
                  <Table
                    size="small"
                    aria-label="customized table"
                    stickyHeader
                    style={{ maxWidth: "100%", minWidth: "auto" }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledCell align="left">Opción</StyledCell>
                        <StyledCell align="left">Cantidad</StyledCell>
                        <StyledCell align="left">Porcentaje</StyledCell>
                        <StyledCell align="left">Votantes</StyledCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {opcionesPorcentaje.map((opcion) => (
                        <StyledRow hover tabIndex={-1}>
                          <StyledCell align="left">{opcion.nombre}</StyledCell>
                          <StyledCell align="left">
                            {opcion.cantidad}
                          </StyledCell>
                          <StyledCell align="left">
                            {opcion.porcentaje} %
                          </StyledCell>
                          <StyledCell>
                            <Grid item sm={3}>
                              <DetalleResult
                                opcion={opcion}
                                resultados={resultados}
                                usuarios={usuarios}
                              />
                            </Grid>
                          </StyledCell>
                        </StyledRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Card>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default ResultadoSurvey;
