import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { ToggleOff, ToggleOn } from "@material-ui/icons";
import { updateEncuesta } from "src/services/admin/vote";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";

const ToggleEncuesta = ({ conjunto, encuesta, encuestas }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const encuestaActiva = encuestas.find((encuest) => encuest.isActive === true);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };
  const confir = async () => {
    setLoading(true);
    try {
      if (encuesta?.isActive !== false) {
        await updateEncuesta(conjunto.id, encuesta.id, {
          isActive: false,
        });
        enqueueSnackbar("Encuesta desactivada.", {
          variant: "success",
        });
      } else {
        if (encuestaActiva) {
          await updateEncuesta(conjunto.id, encuestaActiva.id, {
            isActive: false,
          });
        }
        await updateEncuesta(conjunto.id, encuesta.id, {
          isActive: true,
        });
        enqueueSnackbar("Encuesta activada", {
          variant: "success",
        });
      }
      setLoading(false);
      handleClose();
    } catch (error) {
      enqueueSnackbar("Error al realizar el cambio.", {
        variant: "error",
      });
      setLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <Tooltip
        title={
          encuesta?.isActive !== false
            ? "Desactivar encuesta"
            : "Activar encuesta"
        }
      >
        <IconButton onClick={handleOpen}>
          {encuesta?.isActive !== false ? (
            <ToggleOn style={{ color: "green" }} />
          ) : (
            <ToggleOff style={{ color: "red" }} />
          )}
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <b>{encuesta.title}</b>
        </DialogTitle>
        {loading ? (
          <Box display="flex" justifyContent="center" my={5}>
            <CircularProgress />
          </Box>
        ) : (
          <DialogContent>
            <DialogContentText>
              ¿Seguro de{" "}
              <b>{encuesta?.isActive !== false ? "desactivar" : "activar"} </b>{" "}
              la encuesta?
              <br />
            </DialogContentText>
            {encuestaActiva && encuestaActiva.id !== encuesta.id && (
              <Alert severity="error" style={{ color: "#cc493f" }}>
                <b>
                  ¡Ya existe una encuesta activa, al continuar se desactivará la
                  actual!*
                </b>
                <br />
              </Alert>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={confir}
            disabled={loading}
            color="primary"
            variant="contained"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ToggleEncuesta;
