import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import { DeleteForever } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { deleteEncuesta } from "src/services/admin/vote";
import { useSnackbar } from "notistack";
import { StyledButton } from "src/components/CustomButtons/TgButton";
import useStyles from "src/views/viewAdministrador/areasComunales/useStyle";

const DeleteEncuesta = ({ conjunto, encuesta }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confir = async () => {
    setLoading(true);
    if (encuesta) {
      try {
        await deleteEncuesta(conjunto.id, encuesta.id).then(() => {
          enqueueSnackbar("Se eliminó la encuesta", {
            variant: "info",
          });
        });
        setLoading(false);
        handleClose();
      } catch (error) {
        setLoading(false);
        enqueueSnackbar("Error al eliminar la encuesta.", {
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      <Tooltip title="Eliminar votación">
        <IconButton
          edge="end"
          aria-label="reparar"
          onClick={handleOpen}
          color="primary"
        >
          <DeleteForever />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle>
          <h5>
            <DeleteForever className="me-2" /> Eliminar
          </h5>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Box display="flex" justifyContent="center" my={5}>
              <CircularProgress />
            </Box>
          ) : (
            <Alert severity="error">
              <DialogContentText>
                ¿Está seguro que desea eliminar la encuesta?
              </DialogContentText>
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
         <StyledButton
            autoFocus
            variant="outlined"
            onClick={handleClose}
            color="primary"
            startIcon={
              <BlockIcon fontSize="small" className={classes.iconRed} />
            }
          >
            Cancelar
          </StyledButton>
         <StyledButton
            onClick={confir}
            disabled={loading}
            variant="contained"
            color="primary"
          >
            <DeleteForever className="me-2" />
            Eliminar
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteEncuesta;
