import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/styles/withStyles";

export const StyledButton = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.primary.main}`, // Borde azul
    color: theme.palette.primary.main, // Texto azul
    backgroundColor: "transparent", // Fondo transparente
    transition: "all 0.2s ease-in-out", // Suaviza el hover
    // gap: "8px", // Espacio entre el icono y el texto
    padding: theme.spacing(0.5, 2), // 🔹 Espaciado interno similar al botón original
    borderRadius: "10px",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.15)",
    // minHeight: 28, // 🔹 Altura estándar del botón de Material-UI
    // width: "100%", // 🔹 Ocupa todo el ancho disponible (ajústalo si es necesario)
    "&:hover": {
      backgroundColor: theme.palette.primary.main, // Fondo azul en hover
      color: theme.palette.primary.contrastText, // Texto blanco en hover
      transform: "scale(1.07)", // Cambio mínimo de tamaño
      boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.25)",
      "& svg": {
        color: theme.palette.primary.contrastText, // Cambia el color del icono a blanco
      },
      "&.email-button img": {
        filter: "invert(100%) brightness(1000%)", // Hace la imagen blanca en hover solo en este botón
      },
    },
  },
}))(Button);
