import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Group } from "@material-ui/icons";
import React, { useState } from "react";
import { StyledCell, StyledRow } from "src/components/Common";

const DetalleResult = ({ opcion, resultados, usuarios }) => {
  const [open, setOpen] = useState(false);
  const [selectResult, setSelectResult] = useState({ votantes: [] });

  const handleOpen = () => {
    setSelectResult(opcion);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Participantes">
        <IconButton color="primary" onClick={handleOpen}>
          <Group />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Opción: {selectResult.nombre}
          </DialogTitle>
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Porcentaje: {selectResult.porcentaje}%
          </DialogTitle>
        </Grid>
        <DialogContent>
          <DialogContentText>
            <TableContainer component={Paper} style={{ maxHeight: 440 }}>
              <Table
                size="small"
                aria-label="customized table"
                style={{ maxWidth: "100%", minWidth: "auto" }}
              >
                <TableHead>
                  <TableRow>
                    <StyledCell align="left">Residente</StyledCell>
                    <StyledCell align="left">Respuesta</StyledCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultados
                    .filter((result) => result.confirm === opcion.opcion)
                    .map((result) => {
                      const usuario = usuarios.find(
                        (user) => user.id === result.userId
                      );
                      return (
                        <StyledRow key={result.id} hover tabIndex={-1}>
                          <StyledCell align="left">
                            {usuario
                              ? `${usuario.Nombre} ${usuario.Apellido}`
                              : "Usuario desconocido"}
                          </StyledCell>
                          <StyledCell align="left">
                            {result.confirm ? "Sí" : "No"}
                          </StyledCell>
                        </StyledRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DetalleResult;
