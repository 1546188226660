import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import { StyledCell, StyledRow } from "src/components/Common";
import useStyles from "src/views/conjuntos/useStyles";
import ResultadoSurvey from "../Resultados/ResultadoSurvey";
import DeleteEncuesta from "./DeleteEncuesta";
import ToggleEncuesta from "./ToggleEncuesta";

const TablaSurvey = ({ encuestas, conjunto, usuarios }) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const columns = [
    { id: "nombre", label: "Nombre encuesta" },
    { id: "activo", label: "Estado" },
    { id: "respuestas", label: "Mostrar cada" },
    { id: "acciones", label: "Acciones" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer style={{ marginTop: "10px" }}>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <StyledCell
                key={col.id}
                align="center"
                style={col.id === "nombre" ? { width: "50%" } : {}}
              >
                {col.label}
              </StyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {encuestas
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <StyledRow key={row.id}>
                <StyledCell align="center">{row.title}</StyledCell>
                <StyledCell align="center">
                  {row.isActive ? "Activo" : "No activo"}
                </StyledCell>
                <StyledCell align="center">{row.loop} días</StyledCell>
                <StyledCell align="center">
                  <ResultadoSurvey
                    encuesta={row}
                    conjunto={conjunto}
                    usuarios={usuarios}
                  />
                  <ToggleEncuesta
                    conjunto={conjunto}
                    encuesta={row}
                    encuestas={encuestas}
                  />
                  <DeleteEncuesta conjunto={conjunto} encuesta={row} />
                </StyledCell>
              </StyledRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={encuestas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default TablaSurvey;
