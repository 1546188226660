import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import firebase from "src/Firebase";
import { complexRef } from "src/services/complexServices";

const db = firebase.firestore();

export const getAreasByConjunto = (observer) =>
  complexRef()
    .collection("areascomunales")
    .orderBy("Habilitado", "desc")
    .onSnapshot(observer);

export const getAreasHorasNoHabiles = (conjunto, areaID, observer) =>
  db
    .collection("conjuntos")
    .doc(conjunto)
    .collection("areascomunales")
    .doc(areaID)
    .collection("configuracion")
    .onSnapshot(observer);

export const updateHabilitado = (conjuntoID, areaID, estado) =>
  complexRef(conjuntoID).collection("areascomunales").doc(areaID).update({
    Habilitado: estado,
  });

export const deleteAreaById = (threadKey, userName) =>
  db
    .collection("conjuntos")
    .doc(threadKey)
    .collection("areascomunales")
    .doc(userName)
    .delete();

export const newresv = (idArea, reserva) =>
  complexRef()
    .collection("areascomunales")
    .doc(idArea)
    .collection("reservaciones")
    .add(reserva);

export const updateresv = (conjuntoID, idArea, idDoc, reserva) =>
  complexRef(conjuntoID)
    .collection("areascomunales")
    .doc(idArea)
    .collection("reservaciones")
    .doc(idDoc)
    .update(reserva);

export const deleteresva = (conjuntoID, idArea, idDoc) =>
  complexRef(conjuntoID)
    .collection("areascomunales")
    .doc(idArea)
    .collection("reservaciones")
    .doc(idDoc)
    .delete();

export const getConfigReserve = async (id, day, type = "Horas") => {
  const doc = await complexRef()
    .collection("areascomunales")
    .doc(`${id}/configuracion/${day}`)
    .get();
  if (!doc.exists) {
    return { exists: false };
  }
  let config = doc.data()[type] || [];
  if (type === "Bloques") {
    config = config.map((item, ind) => ({ ...item, id: ind }));
  }
  return {
    exists: config.length > 0,
    config,
  };
};
export const getAllConfigReserve = async (id, type = "Horas") => {
  const query = await complexRef()
    .collection(`areascomunales/${id}/configuracion`)
    .get();
  if (query.empty) {
    return { exists: false, config: [] };
  }
  const config = query.docs.map((doc) => ({
    [type]: doc.data()[type] || [],
    id: doc.id,
  }));
  return {
    exists: config.length > 0,
    config,
  };
};

export const areaReservesInRange = (id, start, observer) =>
  complexRef()
    .collection("areascomunales")
    .doc(id)
    .collection("reservaciones")
    .where("Inicio", ">=", start)
    .onSnapshot(observer);
