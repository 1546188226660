import firebase from 'src/Firebase';

const storage = firebase.storage();
const db = firebase.firestore();

export const saveFiles = async (file, conjunto) => {
  // the return value will be a Promise
  try {
    const snapshot = await storage.ref(`${conjunto}/archivosVotaciones/${file.name}`).put(file);
    const url = await snapshot.ref.getDownloadURL();
    return url;
  } catch (error) {
    throw Error('One failed:', file, error.message);
  }
};
export const sendVotation = (conjunto, data) => db.collection('conjuntos').doc(conjunto).collection('votacion').add(data);
export const newRespuestas = (conjunto, votacionId, indexPregunta,data) => db.collection('conjuntos').doc(conjunto).collection('votacion').doc(votacionId).collection('respuestas').doc(indexPregunta.toString()).set(data);

export const getVotation = (conjunto, observer) => {
  const exp = new Date();
  const ref = db.collection('conjuntos').doc(conjunto).collection('votacion');
  ref.where('Finalizada', '==', false).get().then((snap) => {
    snap.docs.map(async (doc) => {
      if (doc.data().Fin.toDate() <= exp) {
        await ref.doc(doc.id).update({ Finalizada: true });
      }
    });
  });
  return ref.orderBy('Fin', 'desc').onSnapshot(observer);
};
export const deleteVotation = async (conjunto, vote) => {
  const batch = db.batch()
  const voteRef =  db.collection("conjuntos").doc(conjunto).collection("votacion").doc(vote.id)
  // delete vote and responses docs
  batch.delete(voteRef)
  vote.Preguntas.forEach((q, ind) =>
    batch.delete(voteRef.collection("respuestas").doc(`${ind}`))
  );
  return batch.commit()
}
export const getUsuario = (usuarioId) => db.collection('usuarios').doc(usuarioId).get();
export const getToken = (conjuntoId) => db.collection('usuarios').where('ConjuntoUidResidencia', '==', conjuntoId).get();

export const getUserByCuentasNoAlDia = (conjuntoId, usuarioId) => db.collection('conjuntos').doc(conjuntoId).collection('cuentasPorCobrar')
  .where('UsuarioId', '==', usuarioId)
  .where('FechaLimite', '<=', new Date())
  .limit(1)
  .get();

export const getUnidadesHab = (conjuntoID, observer) =>
  db
    .collection("conjuntos")
    .doc(conjuntoID)
    .collection("unidadesHabitacionales")
    .onSnapshot(observer)

export const getUnidadHabitacionalByUser = (conjuntoID, idUsuario) => db.collection('conjuntos')
  .doc(conjuntoID)
  .collection('unidadesHabitacionales').where('IdPropietario', '==', idUsuario)
  .get()

  export const getRespuestasByVotacion = (conjuntoID, idVotacion) => 
  db.collection('conjuntos')
  .doc(conjuntoID)
  .collection('votacion').doc(idVotacion)
  .collection('respuestas')
  .get()

  export const getSurveys = (idconjunto, observer) => {
    db.collection("conjuntos")
      .doc(idconjunto)
      .collection("survey")
      .onSnapshot(observer);
  };

  export const getRespuestasSurvey = (idconjunto, idsurvey, observer) =>
    db
      .collection("conjuntos")
      .doc(idconjunto)
      .collection("survey")
      .doc(idsurvey)
      .collection("votes")
      .onSnapshot(observer);

  export const sendEncuesta = (idconjunto, data) =>
    db
      .collection("conjuntos")
      .doc(idconjunto)
      .collection("survey")
      .add(data);
  export const updateEncuesta = (idconjunto, idsurvey, data) =>
    db
      .collection("conjuntos")
      .doc(idconjunto)
      .collection("survey")
      .doc(idsurvey)
      .update(data);
  export const deleteEncuesta = async (idconjunto, idsurvey) => {
    const votosRef = db
      .collection("conjuntos")
      .doc(idconjunto)
      .collection("survey")
      .doc(idsurvey)
      .collection("votes");
    const snapshot = await votosRef.get();
    const deletePromises = snapshot.docs.map((doc) => doc.ref.delete());
    await Promise.all(deletePromises);
    await db
      .collection("conjuntos")
      .doc(idconjunto)
      .collection("survey")
      .doc(idsurvey)
      .delete();
  };
      
      