import axios from "src/utils/axios";

export const sendNotification = async (
  title = "",
  body = "",
  tokens = [],
) => {
  if (tokens.length < 1) {
    return;
  }
  const paramsPush = {
    titulo: title?.substring(0, 60),
    mensaje: body,
    tokens_dispositivo: tokens,
  };
  await axios.post("qrcode/envia_push/", paramsPush);
};
