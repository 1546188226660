import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const useUsersByUnits = (params) => {
  const fnFilter = params?.filter
  const fnMap = params?.map
  const trailItems = params?.trailItems || []
  const [usersByUnit, setUsersByUnit] = useState([])
  const conjunto = useSelector((state) => state.complex.complex)
  const users = useSelector((state) => state.complex.users)
  const units = useSelector((state) => state.complex.units)

  useEffect(() => {
    if (conjunto?.id) {
      const userResidente = users.filter(
        (item) => item.Roles?.residente === true
      )
      let listOfUsers = []
      units.forEach((unit) => {
        if (unit.IdPropietario) {
          const data = userResidente.find(
            (snap) => snap.id === unit.IdPropietario
          )
          if (data) {
            listOfUsers.push({
              ...data,
              id: `${unit.id}/${data.id}`,
              idUser: data.id,
              idUnit: unit.id,
              Casa: unit.Numero,
              Alicuota: unit.Alicuota,
              fullname: `${data.Nombre} ${data.Apellido}`,
              label: `${unit.Numero} -> ${data?.Nombre} ${data?.Apellido}`,
              tipo:unit.Tipo || '',
            })
          }
        }
        if (unit.IdInquilino) {
          const data = userResidente.find(
            (snap) => snap.id === unit.IdInquilino
          )
          if (data) {
            listOfUsers.push({
              ...data,
              EsPropietario: false,
              id: `${unit.id}/${data.id}`,
              idUser: data.id,
              idUnit: unit.id,
              Casa: unit.Numero,
              Alicuota: unit.Alicuota,
              fullname: `${data.Nombre} ${data.Apellido}`,
              label: `${unit.Numero} -> ${data?.Nombre} ${data?.Apellido}`,
              tipo:unit.Tipo || '',
            })
          }
        }
      })

      listOfUsers
        .sort((a, b) =>
          a.Casa?.toString().localeCompare(b.Casa?.toString(), "en", {
            numeric: true,
          })
        )
      if (fnFilter) {
        listOfUsers = listOfUsers.filter(fnFilter)
      }
      if (fnMap) {
        listOfUsers = listOfUsers.map(fnMap)
      }
      if (trailItems.length > 0){
        listOfUsers = listOfUsers.concat(trailItems)
      }
      setUsersByUnit(listOfUsers)
    }
  }, [users, units])
  return { usersByUnit, conjunto }
}
export default useUsersByUnits
