import {
  Feedback, HelpOutline, Dashboard, Home,
  AddToQueue, People as PeopleIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Ballot,
  HowToVote,
  Email
} from '@material-ui/icons';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import GroupIcon from '@material-ui/icons/Group';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import HistoryOutlined from '@material-ui/icons/HistoryOutlined';

import ContactMailIcon from '@material-ui/icons/ContactMail';

export default {
  superAdministrador: [
    {
      title: 'Dashboard',
      icon: Dashboard,
      href: '/superAdmin/dashboard',
    },
    {
      title: 'Conjuntos',
      icon: Home,
      href: '/superAdmin/conjuntos',
    },
    {
      title: 'Empresas',
      icon: HomeWorkIcon,
      href: '/superAdmin/Company',
    },
    {
      title: 'TGLabs Usuarios',
      icon: ContactMailIcon,
      href: '/superAdmin/usertglabs',
    },
    {
      title: 'Administradores',
      icon: PeopleIcon,
      href: '/superAdmin/administradores',
    },
    {
      title: 'Splash',
      icon: AddToQueue,
      href: '/superAdmin/splash',
    },
    {
      title: 'AllPanicAlerts',
      icon: AddToQueue,
      href: '/superAdmin/allPanics',
    },
    {
      title: 'Feedback',
      icon: Feedback,
      items: [
        {
          title: 'Sugerencias',
          icon: Feedback,
          href: '/superAdmin/sugerencias',
        },
        {
          title: 'Info & Ayuda',
          icon: HelpOutline,
          href: '/superAdmin/infoApp',
        },
        {
          title: 'FAQ',
          icon: QuestionAnswerIcon,
          href: '/superAdmin/FAQ',
        },
      ],
    },
    {
      title: 'Parametros - Botón de pagos ',
      icon: LocalAtmIcon,
      href: '/superAdmin/parametros',
    },
    {
      title: 'Historial de consultas',
      icon: HistoryOutlined,
      href: '/superAdmin/historial',
    },
  ],
  residente: [
    {
      title: 'Dashboard Residente',
      icon: Dashboard,
      href: '/residente/dashboard',
    },
  ],
  administrador: [
    {
      title: 'Dashboard Admin',
      icon: Dashboard,
      href: '/administrador/dashboard',
    }
  ],
  supervisor: [
    {
      title: 'Dashboard Supervisor',
      icon: Dashboard,
      href: '/supervisor/dashboard',
    },
  ],
  empresa: [
    {
      title: 'Dashboard',
      icon: Dashboard,
      href: '/empresa/dashboard',
    },
    {
      title: 'Conjuntos',
      icon: Home,
      href: '/empresa/conjuntos',
    },
    {
      title: 'Administración',
      icon: GroupIcon,
      href: '/empresa/administracion',
    },
  
  ],
  directiva: [
    {
      title: 'Dashboard',
      icon: Dashboard,
      href: '/directiva/dashboard',
    },
    {
      title: 'Residentes',
      icon: GroupIcon,
      href: '/directiva/residentes',
    },
    {
      title: 'Notificaciones',
      icon: Email,
      href: '/directiva/notificaciones',
    },
    {
      title: 'Votación',
      icon: HowToVote,
      href: '/directiva/votacion',
    },
    {
      title: "Reportes",
      icon: Ballot,
      items: [
        {
          title: "Ingresos y Egresos",
          href: "/directiva/reportes/ingvsegr",
        },
        {
          title: "Cuentas por Cobrar",
          href: "/directiva/reportes/cuentasporcobrar",
        },
        {
          title: "Ingresos",
          href: "/directiva/reportes/ingresos",
        },
        {
          title: "Egresos",
          href: "/directiva/reportes/egresos",
        },
        {
          title: "Cartera Vencida",
          href: "/directiva/reportes/cartera",
        },
        {
          title: "Cartera Mensualizada",
          href: "/directiva/reportes/carteraUsuario",
        },
        {
          title: "Historico de Cartera",
          href: "/directiva/reportes/historicoCartera",
        },
        {
          title: "Consolidado",
          href: "/directiva/reportes/consolidado",
        },
        {
          title: "Financiero",
          href: "/directiva/reportes/financiero",
        },
      ],
    },
  ]
}