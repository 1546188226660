import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "90vh",
    padding: theme.spacing(0, 3),
  },
  container: {
    height: "fit-content",
  },
  dividerColor: {
    backgroundColor: "#ffffff",
  },
    snackbarSuccess: {
      backgroundColor: "green", 
      color: "white",
      "& .MuiSnackbarContent-root": {
      backgroundColor: "green", //  contenido también tenga el fondo verde
      color: "white", 
    },
  },
    snackbarError: {
      backgroundColor: "red", 
      color: "white",
      "& .MuiSnackbarContent-root": {
      backgroundColor: "red", 
      color: "white", 
    },
  },
  iconGreen: {
    color: "green",
  },
  iconRed: {
    color: "red",
  },
  iconGray: {
    color: "#800020",
  },
  iconOrange: {
    color: "orange",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%", // Asegura que todas las tarjetas tengan el mismo tamaño
    border: "3px solid #ccc",
    borderRadius: "15px", // Bordes redondeados
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)", // Sombra suave para mejorar el aspecto
    overflow: "hidden", // Evita que el contenido se desborde
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: "0 15px 30px rgba(0, 0, 0, 0.3)",
      borderColor: "#999",
    },
  },
  cardMedia: {
    height: 140,
    objectFit: "cover", // Asegura que la imagen cubra el área del contenedor
    borderTopLeftRadius: "15px", // Bordes redondeados en la esquina superior izquierda
    borderTopRightRadius: "15px",
    backgroundSize: "cover", // Bordes redondeados en la esquina superior derecha
  },
  cardContent: {
    flexGrow: 1, // Permite que el contenido ocupe el espacio restante
    padding: theme.spacing(2),
  },
  cardTitle: {
    fontSize: "1.5rem", // Aumentar el tamaño del título
    color: theme.palette.primary.main,
    textAlign: "left",
    marginBottom: theme.spacing(2), // Espacio adicional debajo del título
  },
  cardDescription: {
    fontSize: "1rem", // Tamaño de la descripción
    color: "#666", // Color de la descripción
    textAlign: "left", // Justificar el texto
    marginTop: theme.spacing(2), // Espacio adicional arriba de la descripción
  },
}));

export default useStyles;
