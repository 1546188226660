import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LoadingScreen from "src/components/Common/LoadingScreen";
import DashboardGuardia from "src/layouts/DashboardGuardia";
import { ReactNotifications } from "react-notifications-component";

const Dashboard = lazy(() => import("./dashboard/index"));
const Alerta = lazy(() => import("./alertas"));
const BitacoraDigital = lazy(() =>
  import("./bitacoraDigital/ListBitacora/ListarBitacora")
);
const BitacorasRechazadas = lazy(() => import("./bitacoraDigital/Rechazadas"));
const VisitasAnticipadas = lazy(() => import("./visitasAnticipadas/index"));
const Ingresos = lazy(() => import("./ingresos"));
const Listas = lazy(() => import("./listas"));
const Eventos = lazy(() => import("./eventos"));
const Rondas = lazy(() => import("./rondas"));
const Familia = lazy(() => import("./familia"));
const Reservas = lazy(() => import("./listaReservas"));
const Parking = lazy(() => import("./parking"));
const BitacorasParking = lazy(() => import("./parking/Bitacora/index"));
const PagosParking = lazy(() => import("./parking/Pagos/index"));
const NotFoundView = lazy(() => import("../errors/NotFoundView"));
const Guardias = lazy(() => import("../viewAdministrador/guardias/index"));
const Guardia = ({ match }) => (
  <DashboardGuardia>
    <Suspense fallback={<LoadingScreen />}>
      <ReactNotifications />
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
        <Route
          path={`${match.url}/dashboard`}
          exact
          render={(props) => <Dashboard {...props} />}
        />
        <Route
          path={`${match.url}/alerta`}
          render={(props) => <Alerta {...props} />}
        />
        <Route
          path={`${match.url}/bitacoraDigital`}
          render={(props) => <BitacoraDigital {...props} />}
        />
        <Route
          path={`${match.url}/bitacoraRechazada`}
          render={(props) => <BitacorasRechazadas {...props} />}
        />
        <Route
          path={`${match.url}/visitasAnticipadas`}
          render={(props) => <VisitasAnticipadas {...props} />}
        />
        <Route
          path={`${match.url}/ingresos`}
          render={(props) => <Ingresos {...props} />}
        />
        <Route
          path={`${match.url}/listas`}
          render={(props) => <Listas {...props} />}
        />
        <Route
          path={`${match.url}/eventos`}
          render={(props) => <Eventos {...props} />}
        />
        <Route
          path={`${match.url}/rondas`}
          render={(props) => <Rondas {...props} />}
        />
        <Route
          path={`${match.url}/reservas`}
          render={(props) => <Reservas {...props} />}
        />
        <Route
          path={`${match.url}/familia`}
          render={(props) => <Familia {...props} />}
        />

        <Route
          path={`${match.url}/gestionarGuardias`}
          render={(props) => <Guardias {...props} />}
        />

        <Route
          path={`${match.url}/parqueadero`}
          render={(props) => <Parking {...props} />}
        />
        <Route
          path={`${match.url}/bitacoraParkey`}
          render={(props) => <BitacorasParking {...props} />}
        />
        <Route
          path={`${match.url}/pagosParkey`}
          render={(props) => <PagosParking {...props} />}
        />

        <Route render={(props) => <NotFoundView {...props} />} />
      </Switch>
    </Suspense>
  </DashboardGuardia>
);

export default Guardia;
