/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import * as Yup from "yup";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import BlockIcon from "@material-ui/icons/Block";
import { useSnackbar } from "notistack";
import { Add } from "@material-ui/icons";
import { sendEncuesta, updateEncuesta } from "src/services/admin/vote";
import { StyledButton } from "src/components/CustomButtons/TgButton";
import useStyles from "../../metodosDePago/activos/useStyles";

const NewSurvey = ({ encuestas, conjunto }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const encuestaActiva = encuestas.find(
    (encuesta) => encuesta.isActive === true
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };

  return (
    <>
      <StyledButton
        variant="outlined"
        color="primary"
        style={{ marginTop: 10 }}
        onClick={handleOpen}
      >
        <Add className="me-2" />
        Nueva encuesta
      </StyledButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <Formik
          initialValues={{
            Encuesta: "",
            Loop: 30,
          }}
          validationSchema={Yup.object().shape({
            Encuesta: Yup.string()
              .required("Se requiere rellenar este campo!")
              .max(100, "El máximo de caracteres es 100"),
            Loop: Yup.number()
              .min(1, "El valor mínimo es 1")
              .max(30, "El valor máximo es 30")
              .integer("Solo se permiten números enteros")
              .typeError("Debe ser un número válido"),
          })}
          onSubmit={async (values) => {
            setLoading(true);
            const existEncuesta = encuestas.some(
              (encuesta) => encuesta.title === values.Encuesta
            );
            try {
              if (!existEncuesta) {
                const valueData = {};
                valueData.title = values.Encuesta;
                valueData.loop = values.Loop || 30;
                valueData.isActive = true;
                if (encuestaActiva) {
                  await updateEncuesta(conjunto.id, encuestaActiva.id, {
                    isActive: false,
                  });
                }
                await sendEncuesta(conjunto.id, valueData).then(() => {
                  setLoading(false);
                  enqueueSnackbar("¡Encuesta creada!", {
                    variant: "success",
                  });
                  handleClose();
                });
              } else {
                enqueueSnackbar("¡Ya existe una encuesta con ese nombre!", {
                  variant: "error",
                });
                setLoading(false);
              }
            } catch (error) {
              enqueueSnackbar("Error al crear la encuesta", {
                variant: "error",
              });
              setLoading(false);
              handleClose();
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            isSubmitting,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                <b>Crear una nueva encuesta</b>
              </DialogTitle>
              {loading ? (
                <Box display="flex" justifyContent="center" my={5}>
                  <CircularProgress />
                </Box>
              ) : (
                <DialogContent>
                  {encuestaActiva && (
                    <DialogContentText
                      className={classes.textAlert}
                      style={{ color: "#cc493f" }}
                    >
                      <b>
                        ¡Ya existe una encuesta activa, al continuar se
                        desactivará la encuesta actual y se activará la nueva!*
                      </b>
                      <br />
                    </DialogContentText>
                  )}
                  <DialogContentText>Nueva encuesta:</DialogContentText>
                  <TextField
                    name="Encuesta"
                    label="Encuesta"
                    id="pregunta"
                    variant="outlined"
                    fullWidth
                    minRows={2}
                    value={values.Encuesta}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Encuesta && Boolean(errors.Encuesta)}
                    helperText={touched.Encuesta && errors.Encuesta}
                  />
                  <DialogContentText>Mostrar encuesta cada:</DialogContentText>
                  <TextField
                    name="Loop"
                    label="Días (por defecto 30)"
                    type="number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    inputProps={{
                      min: 1,
                      max: 30,
                      step: 1,
                    }}
                    onChange={handleChange}
                    value={values.Loop}
                    onBlur={handleBlur}
                    error={touched.Loop && Boolean(errors.Loop)}
                    helperText={touched.Loop && errors.Loop}
                  />
                </DialogContent>
              )}
              <DialogActions>
                <StyledButton
                  autoFocus
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  startIcon={<BlockIcon fontSize="small" className={classes.iconRed} />}
                >
                  Cancelar
                </StyledButton>
                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Confirmar
                </StyledButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default NewSurvey;
