import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import { useSelector } from "react-redux";
import useUsers from "src/hooks/useUsers";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
import { getSurveys } from "src/services/admin/vote";
import { HowToVote } from "@material-ui/icons";
import { NoInfo } from "src/components/Common";
import NewSurvey from "./New/NewSurvey";
import TablaSurvey from "./Tabla/TablaSurvey";
import useStyles from "../votacion/Styles";

const Encuestas = () => {
  const classes = useStyles();
  const { usuarios } = useUsers();
  const { enqueueSnackbar } = useSnackbar();
  const conjunto = useSelector((state) => state.complex.complex);
  const [encuestas, setEncuestas] = useState([]);

  useEffect(() => {  
    const observer = (snapshot) => {
      try {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEncuestas(data);
      } catch (error) {
        enqueueSnackbar("Error al cargar las encuestas", {
          variant: "error",
        });
      }
    };
  
    const unsubscribe = getSurveys(conjunto.id, observer);
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [conjunto.id, enqueueSnackbar]);
  

  return (
    <Paper className={classes.root}>
      <div>
        <h3>
          <HowToVote /> Encuestas
        </h3>
      </div>
      <Grid item xs={12}>
        <Alert severity="info">
          - Solo se permite realizar una pregunta con respuestas únicamente de
          Sí / No.
          <br />- Recuerda que solamente se puede tener una encuesta activa a la
          vez.
          <br />- Las encuestas no tienen una fecha límite. Y se repite cada
          cierto periodo (días).
        </Alert>
      </Grid>
      <NewSurvey encuestas={encuestas} conjunto={conjunto} />
      {encuestas.length > 0 ? (
        <TablaSurvey
          encuestas={encuestas}
          conjunto={conjunto}
          usuarios={usuarios}
        />
      ) : (
        <NoInfo />
      )}
    </Paper>
  );
};

export default Encuestas;
