import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  chart: {
    padding: theme.spacing(2),
  },
  fin: {
    backgroundColor: "#95D890",
    padding: "10px",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
  },
  continue: {
    backgroundColor: "#e74c3c",
    padding: "10px",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
  },
}));
export default useStyles;
